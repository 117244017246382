@import '../../../../components/Utils/DataTable/style.scss';

.custom__padding {
  padding-bottom: 10px;
}

.custom-data-table {
  @extend .custom-data-table;
  overflow: visible !important;
  margin-bottom: 30px !important;
  padding-bottom: 140px !important;

  header {
    display: none;
  }
}

.custom-min--height {
  min-height: 135px;
}

.table_at__details {
  overflow-y: visible;
}
