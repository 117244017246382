.checkBoxLabel {
  background-color: #f7f7f7;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.3rem);
  padding: 0.3rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 0.7;
  color: #303030;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #cecece;
  border-radius: 20px;
}

.activeCheckBox {
  background-color: #ffb6b6;
  border: 2px solid #ffb6b6;
  color: #303030;
}

.simpleTable {
  td {
    padding: 0.1rem !important;
    border: 1px solid #e9ecef !important;
  }
}
