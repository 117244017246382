.simple-table {
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
}

.simple-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.simple-table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

.simple-table th,
.simple-table td {
  padding: 0.625em;
  text-align: center;
  min-width: 50px !important;
}

.simple-table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.simple-table th {
  background-color: #29b48f;
  color: white;
}

.simple-table td {
  div {
    white-space: normal;

    span {
      white-space: normal;
    }
  }

  .avatar {
    min-width: 30px;
    height: 30px;
    margin-left: 0;
    margin-right: 5px;
    align-self: center;
  }
}

.simple-table tr:hover {
  background-color: white;
  color: #29b48f !important;
  cursor: pointer;
}

@media screen and (max-width: 880px) {
  .simple-table {
    border: 0;
  }

  .simple-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 0px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .simple-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .simple-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .simple-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .simple-table td:last-child {
    border-bottom: 0;
  }
}
