.hide-all-buttons .rdl-move.rdl-move-all.rdl-move-right {
  display: none;
}

.hide-all-buttons .rdl-move.rdl-move-all.rdl-move-left {
  display: none;
}

.w-120-px {
  width: 120px;
}

@media screen and (max-width: 1600px) {
  .react-datepicker__month .react-datepicker__month-text {
    width: 3rem !important;
  }
}

@media screen and (max-width: 1300px) {
  .react-datepicker__month .react-datepicker__month-text {
    width: 2.5rem !important;
  }
}
