.department-stats {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.stats-header {
  display: flex;
  justify-content: space-between;
  background-color: #e0e0e0;
  padding: 5px;
  font-weight: bold;
}

.stats-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.stats-row.total {
  font-weight: bold;
  border-top: 2px solid #000;
  border-bottom: none;
}

.stats-row > div {
  flex: 1;
  text-align: center;
}

.stats-row > div:first-child {
  text-align: left;
}

.stats-row > div:last-child {
  text-align: right;
}
