.table-print {
  width: 100%;
  margin-bottom: 1rem;
  color: #515151;
  border: 1px solid black;

}

div.print_report>* {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8px;
}

tr.gray-background {
  background-color: gray;
  print-color-adjust: exact;
}

div.print_report h1 {
  font-size: 8px;
  letter-spacing: 1px;
  text-align: center;
}

div.print_report h2 {
  font-size: 8px;
}

div.print_report p {
  font-size: 8px;
  margin-bottom: 2px;
  font-family: Arial, Helvetica, sans-serif;

}

.print_report thead td {
  text-align: center;
  font-weight: bold;
  border: 1px solid black;

}

.print_report tbody td {
  text-align: center;
  border-bottom: inherit;
  border: 1px solid black;
}