.card-header-title {
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: left;
  padding-bottom: 2px;
  padding-top: 2px;
  white-space: nowrap;
}

.top-header-green {
  background: #29b48f !important;
  justify-content: left;
}
