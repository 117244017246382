// Importa las variables de Bootstrap
@import "~bootstrap/scss/functions";
@import '../../../assets/stylesheets/custom_variables.scss';

.attendances_control_clock form label,
.attendances_control_clock form select,
.attendances_control_clock form input {
  font-family: Arial, sans-serif;
  font-size: 8px;
}

.attendances_control_clock .DateRangePicker .DateRangePickerInput .DateInput .DateInput_input {
  font-family: Arial, sans-serif !important;
  font-size: 8px !important;
}

.attendances_control_clock form .btn {
  font-family: Arial, sans-serif !important;
  font-size: 8px !important;
}

.attendances_control_clock .custom-data-table .form-control {
  font-family: Arial, sans-serif !important;
  font-size: 8px !important;
}

.attendances_control_clock .custom-data-table .iERBAK {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 8px !important;
}

.attendances_control_clock .custom-data-table .rdt_TableHeadRow .rdt_TableCol {
  font-family: Arial, sans-serif;
  font-size: 8px;
}

.attendances_control_clock .custom-data-table .rdt_TableCell {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 8px !important;
}

.attendances_control_clock .rdt_Pagination {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 8px !important;
}

.attendances_control_clock small {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 8px !important;
}


.attendances_control_clock .CalendarDay {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 8px !important;
}

.attendances_control_clock .CalendarMonth_caption .form-control {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 8px !important;
}