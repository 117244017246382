.payroll-container {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
}

.payroll-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
  border-bottom: 0.8px solid gray;
}

.amount {
  text-align: right;
  min-width: 100px;
}

.total {
  font-weight: bold;
}
