@import '~bootstrap/scss/functions';

.custom-data-table {
  .rdt_TableCell {
    padding-right: 10px;
    padding-left: 10px;
    overflow-y: hidden;

    div {
      white-space: normal !important;

      span {
        white-space: normal !important;
      }
    }
  }
}

.top-header-green-dt {
  background: #29b48f !important;
  border-radius: 20px 20px 0px 0px;
}

.card-body-padding {
  padding: 10px 20px 3px 20px !important;
}

.card.card-dt {
  z-index: unset;
}
