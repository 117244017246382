$color_1: #fffefe;
$color_2: #949495;
$color_3: #0c0c0c;
$color_4: #f7f7f6;
$color_5: #f8c444;
$color_6: #8054fc;
$color_7: black;
$color_8: #963d1f;
$color_9: #868686;
$font-family_1: futura-pt-bold, sans-serif;
$background-color_1: #f6f7f7;
$background-color_2: var(--TopHeaderColor);
$background-color_3: #ffffff;
$background-color_4: #963d1f;
$background-color_5: #a85438;
$background-color_6: #ccc;
$background-color_7: #f6a585;
$border-color_1: transparent;

#HRDashboard .CardBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $background-color_1;
  border-radius: 20px !important;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: $background-color_1;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.3);
  }
}

#HRDashboard .top-header {
  padding-bottom: 2px;
  padding-top: 4px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
  text-align: center;

  p {
    font-weight: bold;
    color: $color_1;
    font-size: 14px;
    white-space: nowrap;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
}

#HRDashboard .Title {
  align-self: center;

  img {
    padding: 0.9rem;
  }

  div {
    margin: auto;
    font-family: $font-family_1;
  }
}

#HRDashboard .Number {
  font-size: 30px;
  color: $color_2;
  font-weight: bold;
  font-family: $font-family_1;
  line-height: normal;
  text-align: center;
}

#HRDashboard .NotNumber {
  font-size: 20px;
  color: $color_3;
  font-weight: bold;
  font-family: $font-family_1;
  line-height: normal;
  text-align: center;
}

#HRDashboard .DownloadButton {
  text-align: right;
  button {
    color: $color_4;
    border-radius: 20px;
    border-color: $border-color_1;
    text-align: center;
    height: 40px;
    width: 40px;
  }
}

#HRDashboard .TopHeaderStyle {
  color: $color_1;
  background-color: $background-color_2;
  text-align: center;

  div {
    min-width: 97%;
    max-width: 97%;
  }
}

#HRDashboard .flecha {
  font-size: 2rem;
  font-weight: bold;
}

#HRDashboard .ViewMoreButton {
  text-align: right;
  button {
    color: $color_4;
    border-radius: 20px;
    border-color: $border-color_1;
    text-align: center;
    height: 30px;
    width: 70px;
  }
}

#HRDashboard .Centralize {
  text-align: center;
  text-align: -webkit-center;
}

#HRDashboard .Subtitle {
  color: $color_4;
  width: 100%;

  p {
    font-weight: bold;
    font-family: $font-family_1;
    border-radius: 20px;
    border-color: $border-color_1;
    font-size: 1rem;
  }
}

#HRDashboard .Height {
  align-items: center;
}

#HRDashboard circle {
  fill: none;
  stroke-width: 7%;
  transform: rotate(-90deg);
  transform-origin: 50%;

  &:nth-child(2) {
    animation: 0.35s linear forwards;
  }
}

#HRDashboard .Percentage {
  position: relative;
  text-align: end;
  width: 85px;
  span {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
  }

  p {
    text-align: left;
    font-size: 1.5em;
    margin-top: 10%;
    color: $color_3;
    font-family: $font-family_1;
  }
}

#HRDashboard .AntiquityAverage {
  display: flex;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

#HRDashboard .AntiquityAverageImage {
  padding-left: 17%;

  img {
    width: 81%;
  }
}

#HRDashboard .Centralizing {
  align-items: center;
}

#HRDashboard .EmployeeDistributionBody {
  text-align: center;
}

#HRDashboard .MarkContent {
  padding-right: 3%;
  padding-left: 3%;
  background-color: $background-color_3;
  border-radius: 20px;
  height: 460px;
}

#HRDashboard .Branch {
  width: 33%;
  margin: 1%;
}

#HRDashboard .ServiceProvision {
  width: 63%;
  margin: 1%;
}

#HRDashboard .setheigh {
  height: 32rem;
}

#HRDashboard .chart {
  position: relative;
  display: flex;
  width: 100%;
  border: 11px transparent;
  align-self: end;
}

#HRDashboard .chartWords {
  position: relative;
  display: flex;
  width: 100%;
  border: 11px transparent;
}

#HRDashboard .Separate {
  height: 220px;
  position: relative;
}

#HRDashboard .Verticalbar {
  position: relative;
  width: 32%;
  background-color: $background-color_4;
  z-index: 2;
  border-radius: 20px 20px 0 0;
  align-self: end;
}

#HRDashboard .VerticalbarWithoutBackgrounfcolor {
  position: relative;
  width: 14px;
  z-index: 2;
  border-radius: 20px 20px 0 0;
  align-self: end;
}

#HRDashboard .SeparateDoubleBar {
  margin-left: 2%;
  margin-right: 2%;
}

#HRDashboard .MenColor {
  color: $color_5;
  padding-left: 5px;
}

#HRDashboard .WomenColor {
  color: $color_6;
}

#HRDashboard .Bar {
  &:hover {
    background-color: $background-color_5;
  }
}

#HRDashboard .line {
  position: absolute;
  width: 100%;
  height: 0.1%;
  background-color: $background-color_6;
  z-index: 1;
}

#HRDashboard .rotated-text {
  transform: rotate(-90deg);
  transform-origin: left top;
  font-family: $font-family_1;
  width: 12rem;
  white-space: nowrap;
  text-align: end;
}

#HRDashboard .content-Bar {
  align-items: self-end;
  margin: 5%;
}

#HRDashboard .Division {
  align-self: end;
  text-align: -webkit-center;
  padding-left: 1% !important;
  padding-right: 1% !important;
}

#HRDashboard .Col-Max-width-Modified {
  max-width: 13px;
}

#HRDashboard .position-down {
  align-self: end;
  text-align: -webkit-center;
  min-height: 100%;
  padding-left: 1% !important;
  padding-right: 1% !important;
}

#HRDashboard .position-right {
  min-width: 7.5%;
  min-height: 100%;
  display: flex;
}

#HRDashboard .Justify {
  font-family: $font-family_1;
  min-width: 100%;
  font-size: 137%;
  color: $color_7;
}

#HRDashboard .ServiceProvisionTitle {
  min-height: 10%;
  padding-top: 20px;
  align-content: center;
}

#HRDashboard .MapHeight {
  max-height: 85%;
}

#HRDashboard .DescriptionBar {
  color: $color_8;
}

#HRDashboard .Horizontalbar {
  position: relative;
  background-color: $background-color_7;
  z-index: 2;
  border-radius: 0 20px 20px 0;
  align-self: center;
  height: 1rem;
}

#HRDashboard .HorizontalbarWithoutColor {
  position: relative;
  z-index: 2;
  border-radius: 0 20px 20px 0;
  align-self: center;
  height: 1rem;
}

#HRDashboard .CountryContentText {
  align-self: center;
  text-align: left;
}

#HRDashboard .CountryText {
  font-size: 1rem;
}

#HRDashboard .CountryCountNumber {
  font-size: 1rem;
  padding-left: 80%;
  font-family: $font-family_1;
}

#HRDashboard .AgeDistribution {
  background-color: $background-color_3;
  border-radius: 20px;
  height: 100%;
}

#HRDashboard .AgeDistributionGraphic {
  background-color: $background-color_3;
  border-radius: 20px;
  height: 100%;
  display: flex;
}

#HRDashboard .stretch {
  height: 22rem;
}

#HRDashboard .extend-down {
  height: 100%;
}

#HRDashboard .adjust-pad {
  padding: 2%;
}

#HRDashboard .NationalityPadCard {
  background-color: $background-color_3;
  border-radius: 20px;
  height: 100%;
}

#HRDashboard .Nationality-adjust-pad {
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 3%;
  padding-bottom: 3%;
}

#HRDashboard .adjust-margin {
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
}

#HRDashboard .IconCountryFlagSize {
  width: 100%;
}

#HRDashboard .tense {
  text-align: -webkit-center;
  align-self: center;
}

#HRDashboard .countryflag {
  width: 21px;
  height: 15px;
}

#HRDashboard .AgeDistributionText {
  font-weight: bold;
  font-size: 125%;
}

#HRDashboard .AgeDistributionNumber {
  font-weight: bold;
  font-family: $font-family_1;
  font-size: 115%;
}

#HRDashboard path {
  &:hover {
    fill: #ff6767;
  }
}

#HRDashboard .IgnorePadding {
  padding-right: 1% !important;
  padding-left: 1% !important;
}

#HRDashboard .point {
  font-size: 180%;
}

#HRDashboard .pointgender {
  font-weight: bold;
  font-family: $font-family_1;
  font-size: 65%;
}

#HRDashboard .GenderSimbol {
  font-size: 120%;
  margin-bottom: -20%;
  margin-top: -16%;
}

#HRDashboard .GenderSimbolModified {
  margin-top: -7%;
}

#HRDashboard .paddingLeftOFF {
  padding-left: 0% !important;
}

#HRDashboard .DisplayFlex {
  display: flex;
}

#HRDashboard .WidthModified {
  width: 100%;
}

#HRDashboard .DividingParts {
  min-height: 17%;
}

#HRDashboard .minheight {
  min-height: 10%;
}

#HRDashboard .position-center {
  align-self: end;
  text-align: -webkit-center;
  min-height: 100%;
  padding-right: 0% !important;
  padding-left: 0% !important;
}

#HRDashboard .SeparatebyMiddleAndAlign {
  min-width: 15px;
  align-self: end;
}

#HRDashboard .AgeRange {
  font-size: 70%;
  font-family: $font-family_1;
}

#HRDashboard .AgeRangeBotton {
  position: absolute;
  height: 1rem;
  min-width: 30px;
}

#HRDashboard .AlighAgerange {
  padding-bottom: 5%;
  padding-top: 4%;
  padding-left: 15px;
}

#HRDashboard .AlighAgerangeBar {
  padding-bottom: 8%;
  padding-left: 15px;
  width: 80%;
}

#HRDashboard .AtenuatedColor {
  color: $color_9;
}

#HRDashboard .CentralizeTextMap {
  align-self: center;
  margin-bottom: 10%;
}

#HRDashboard .RiseRow {
  height: 14rem;
}

#HRDashboard .CenteredDiv {
  text-align: center;
}

#HRDashboard .LittleMargin {
  margin-top: 1%;
  margin-bottom: 1%;
}

#HRDashboard .BlankCard {
  height: 145px;
}
