@import '~bootstrap/scss/functions';
@import '../../../assets/stylesheets/custom_variables.scss';

.div-data__table {
  margin-bottom: -150px;
}

.custom-data-table {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    background-color: $gray-200 !important;
  }

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    background-color: transparentize($white, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken($light, 6%);
    border-radius: 10px;
    outline: none;
  }

  header {
    padding-top: 20px;
    border-right: 0;
    background-color: $gray-200;

    .row {
      margin-right: 0;
      flex: 1;

      .search {
        padding-right: 0;
      }
    }

    @media (max-width: 767px) {
      padding: 20px 16px 4px 10px !important;

      .margin-sm {
        margin-left: 0.7rem;
      }
    }
  }

  .rdt_Table,
  .rdt_Pagination {
    background-color: $gray-200;
    // padding: 0 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .rdt_TableBody {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
      background-color: darken($light, 7%);
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparentize($gray-500, 0.6);
      border-radius: 10px;
      outline: none;
    }
  }

  .rdt_TableRow {
    background-color: $gray-200;
  }

  .rdt_TableHeadRow {
    min-height: 50px;
    background-color: $primary;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-style: none;

    .rdt_TableCol {
      font-family: 'Inter', sans-serif;

      .rdt_TableCol_Sortable {
        color: $white;
      }

      .rdt_TableCol_Sortable:hover:not(:focus) {
        color: rgba(255, 255, 255, 0.54);
      }
    }
  }

  .rdt_TableRow {
    color: $dark;

    &:not(:last-of-type) {
      border-color: $gray-400;
    }

    &:hover {
      color: $primary;
      background-color: transparentize($white, 0.7);
    }

    &.inactive {
      background-color: transparentize($danger, 0.7);

      .avatar {
        opacity: 0.5;
        color: transparentize($dark, 0.5);
      }
    }
  }

  .rdt_TableCell {
    padding-right: 10px;
    padding-left: 10px;
    overflow-y: hidden;
    font-size: 12px !important;

    div {
      white-space: normal;

      span {
        white-space: normal;
      }
    }

    .avatar {
      min-width: 30px;
      height: 30px;
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .iERBAK {
    background-color: transparent !important;
    color: $gray-500 !important;
    text-transform: uppercase;
    // margin-bottom: 30px;
  }
}

.arial-dt {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 8px !important;
}
