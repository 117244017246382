html {
  position: relative;
  height: 100%;
}

body {
  height: 100%;
  font-size: $font-size-base * 0.75;
  background-color: $white !important;
}

#root {
  height: 100%;
}

.modal-open .modal {
  backdrop-filter: blur(4px);
}

.img-login {
  height: 100%;
  width: 100%;
  background-image: url(../images/fondo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  position: absolute;
}

// TEXT
h1 {
  font-family: 'Jost', sans-serif;
  font-weight: 700;
  letter-spacing: 4px;
}

h2 {
  font-weight: 700;
  letter-spacing: 3.2px;
}

h3 {
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
}

h4 {
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  color: $gray-500;
  margin-top: 30px;
  margin-bottom: 50px;
}

h5 {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
}

p {
  font-family: $font-family-sans-serif;
  font-weight: 400;
}

pre {
  white-space: pre-wrap;
  font-size: 13px;
}

a {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  font-size: $font-size-sm;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    outline: 0;
  }
}

label {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  color: $gray-500;
  font-size: $font-size-base * 0.75;
}

small {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  font-size: $font-size-base * 0.75;
}

.futura {
  font-family: futura-pt, sans-serif;
  letter-spacing: 1px;

  &.font-size-title {
    font-size: 15px;
  }
}

// BUTTONS
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant(
      $value,
      $value,
      $hover-background: darken($value, 6.5%),
      $hover-border: darken($value, 6.5%),
      $active-background: darken($value, 10%),
      $active-border: darken($value, 10%)
    );
    color: $white;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $white;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      box-shadow: 2px -4px 0 -1px transparentize($value, 0.4), -2px 3px 0 0px transparentize($value, 0.4);
    }

    &.inverse-hover {
      border-color: $white;

      &:hover,
      &:focus,
      &:active,
      &.active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        background-color: lighten($value, 6.5%);
        border-color: $white;
      }
    }

    &.no-border {
      border-color: unset;

      &:hover,
      &:focus,
      &:active,
      &.active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        border-color: unset;
      }
    }
  }

  .btn-outline-#{$color} {
    @include button-outline-variant(
      $value,
      $color-hover: color-yiq($value),
      $active-background: $value,
      $active-border: $value
    );

    &:hover,
    &:focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $color;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      box-shadow: 2px -4px 0 -1px transparentize($value, 0.4), -2px 3px 0 0px transparentize($value, 0.4);
    }
  }

  .btn-circle-#{$color} {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    svg {
      width: 21px;
      height: 21px;
    }

    color: $value;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $value;
      background-color: $light;
      border-color: $light;

      &.toogle {
        color: $light;
        background-color: $info;
        border-color: $info;
      }
    }

    &:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $light;
      border-color: $light;
      color: $value;
      box-shadow: 2px -4px 0 -1px transparentize($light, 0.4), -2px 3px 0 0px transparentize($light, 0.4);

      &.toogle {
        color: $light;
        background-color: $info;
        border-color: $info;
      }
    }
  }
}

.modal-content-dt .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: transparent;
  border-radius: 30px;
  outline: 0;
}

.btn {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'Inter', sans-serif;

  &:disabled {
    background: #b1b1b1;
    border-color: #b1b1b1;
    color: $white;
  }

  &-circle {
    &-dark {
      background-color: transparent;
      border-color: transparent;
      color: $gray-600;

      &:hover,
      &:focus {
        border-color: $gray-100;
        background-color: $gray-100;
        color: $gray-600;
      }

      &:focus {
        box-shadow: 2px -4px 0 -1px transparentize($gray-100, 0.4), -2px 3px 0 0px transparentize($gray-100, 0.4);
      }
    }

    &-light {
      background-color: transparent;
      border-color: transparent;
      color: $gray-500;

      &:hover,
      &:focus {
        border-color: $gray-100;
        background-color: $gray-100;
        color: $gray-500;
      }

      &:focus {
        box-shadow: 2px -4px 0 -1px transparentize($gray-100, 0.4), -2px 3px 0 0px transparentize($gray-100, 0.4);
      }
    }

    &:disabled {
      background: transparent;
      color: $gray-400;
      border-color: transparent;

      &:hover,
      &:focus {
        background: transparent;
        color: $gray-400;
        border-color: transparent;
      }
    }
  }
}

.btn-link {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: $font-size-sm;
  color: $dark;

  &:hover {
    background-color: $light;
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 2px -4px 0 -1px transparentize($light, 0.4), -2px 3px 0 0px transparentize($light, 0.4);
  }
}

.btn.underline {
  color: $primary;
  font-weight: bold;
  padding: 0;
  text-decoration: underline;
  text-transform: capitalize;

  &:active,
  &:focus,
  &:hover {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
    color: lighten($primary, 20);
  }
}

// FOOTER
.footer {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  height: 60px;
}

.full-height {
  height: calc(100vh - 120px);
}

.carousel-home {
  margin: 0 -15px;
  width: auto;
}

.carousel-show {
  position: relative;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}

// PILLS
.nav-pills {
  flex-wrap: inherit;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    background-color: transparentize($white, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken($light, 6%);
    border-radius: 10px;
    outline: none;
  }

  &.employee {
    position: sticky;
    top: 190px;
    z-index: 301;
    background-color: $white;
    padding-bottom: 10px;

    @media (max-width: 767px) {
      top: 128px;
      background-color: transparent;
      padding-bottom: 0;
    }
  }

  &.employee-new {
    top: 107px !important;

    @media (max-width: 440px) {
      top: 100px !important;
    }
  }

  &.nav-admin {
    top: 0 !important;
    opacity: 1;
    transition: 0.1s all ease-in-out;
  }

  &.hidden-nav-admin {
    opacity: 0;
    transition: 0.1s all ease-in-out;
  }

  &.ab-pills {
    overflow-x: auto;
    z-index: 1;
  }

  .nav-item {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 3px;
    white-space: nowrap;

    .nav-link {
      padding: 0.3rem 0.75rem;
      color: $gray-500;
      text-transform: none;
      font-size: $font-size-base * 0.75;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      letter-spacing: 0.1em;
      border: 2px solid transparent;

      &:hover {
        background-color: $light;
        color: $gray-500;
      }

      &:focus,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active:focus {
        background-color: $primary;
        color: #fff;
        box-shadow: 2px -4px 0 -1px transparentize($primary, 0.4), -2px 3px 0 0px transparentize($primary, 0.4);
        outline: 0;
      }

      svg {
        width: 15px;
        height: 15px;
        position: relative;
        top: 0;
        margin-right: 5px;
      }

      &.active {
        color: #fff;
        border: 2px solid $primary;
        background-color: $primary;

        &:hover {
          color: #fff;
          border: 2px solid $primary;
          background-color: $primary;
        }

        &:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
          background-color: $primary;
          color: #fff;
          box-shadow: 2px -4px 0 -1px transparentize($primary, 0.4), -2px 3px 0 0px transparentize($primary, 0.4);
          outline: 0;
        }
      }

      &.disabled {
        color: $gray-400;
        border: 2px solid transparent;
        background: transparent;

        &:hover {
          color: $gray-400;
          border: 2px solid transparent;
          background: transparent;
        }
      }
    }

    &:last-of-type {
      .nav-link {
        margin-right: 0;
      }
    }
  }
}

.scroll-tabs {
  width: calc(100vw - 550px);
  overflow-x: auto;
  padding: 5px 2px;

  .nav-pills {
    flex-wrap: nowrap;

    .nav-item {
      display: inline-block;

      .nav-link {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }
  }

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    background-color: darken($light, 4%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparentize(white, 0.6);
    border-radius: 10px;
    outline: none;
  }
}

.round-10 {
  border-radius: 10px;
}

.new {
  .scroll-tabs {
    width: 100%;
  }
}

// CARD
.card {
  background: $gray-200;
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 30px;
  z-index: auto;

  &.box-shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  }

  // .card-subtitle {
  //   font-size: 12px;
  // }

  // padding: 15px;
  .card-title {
    color: $gray-700;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;

    @media (max-width: 991px) {
      font-size: 14px;
    }
  }

  &.register-attendance-margin {
    margin-bottom: 10px !important;
  }

  .info {
    letter-spacing: 0.025rem;
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .type {
      font-family: $font-family-sans-serif;
      font-weight: 500;
      color: $gray-600;
      font-size: $font-size-base * 0.75;
    }

    .line {
      flex: 1;
      border-bottom: 1px solid $gray-300;
      height: 2px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .underline {
      text-decoration: underline;
      color: $primary;
    }

    .answer {
      font-family: $font-family-sans-serif;
      font-weight: 500;
      color: $gray-700;
      font-size: $font-size-base * 0.75;
      text-align: right;
      align-self: flex-start;

      @media (max-width: 374px) {
        width: 160px;
      }

      a {
        font-family: $font-family-sans-serif;
        font-weight: 500;
        color: $primary;
        font-size: $font-size-sm;
      }
    }
  }

  .card-header {
    padding: 0;
    background: $gray-200 !important;
    border: none;
    color: #959595;
    font-family: futura-pt-bold, sans-serif;
    font-weight: 500;
    font-size: $font-size-sm;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .list-group-item {
    padding: 10px;

    p {
      margin: 0;

      &:first-of-type {
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #303030;
        margin-bottom: 3px;
      }

      font-family: Roboto;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.15em;
      color: #303030;
    }
  }
}

// INFO SIDEBAR
.avatar {
  width: 30px;
  display: block;
  position: relative;

  &.employee-card {
    width: 50px;
  }

  &.employee {
    height: 100px;
    width: 100px;

    @media (max-width: 767px) {
      width: 100px;
      height: 100px;
      margin-left: 5px;
      margin-bottom: 10px;
    }

    .content {
      svg {
        width: 80px;
        height: 80px;
      }
    }
  }

  &.company {
    height: 100px;
    width: 160px;
    margin-right: 10px;

    @media (max-width: 767px) {
      height: 90px;
      width: 125px;
    }

    @media (max-width: 350px) {
      width: 120px;
    }

    .content {
      border-radius: 0;
      background-color: $white;

      img {
        object-fit: contain;
      }

      svg {
        width: 80px;
        height: 80px;
      }
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 65%;
    }
  }

  &.companyNav {
    .content {
      padding: 3px;

      img {
        object-fit: contain;
      }
    }
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-400;

    &.empty {
      background-color: $light;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    svg {
      width: 20px;
      height: 20px;
      left: 0;
    }
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.employee-info {
  padding-left: 20px;

  li {
    list-style: none;
    color: $gray-700;
    margin: 15px 0;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: $font-size-sm;

    svg {
      color: $gray-500;
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
}

.sidebar-employee {
  border-right: 1px solid $gray-400;
  min-height: calc(100vh - 80px);
}

// CONTENT
.content-body {
  padding-left: 285px; // original: 270px
  padding-right: 25.5px;
  padding-top: 46px;
  position: relative;

  @media (max-width: 340px) {
    padding-right: 1.35rem;
  }

  @media (max-width: 285px) {
    padding-right: 0.2rem;
  }

  &.content-company {
    &.sps--blw {
      padding-top: 94px;
    }
  }
}

.top-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: $white;
  box-shadow: $box-shadow-sm;
  z-index: 299;
  opacity: 0;
  transition: all 0.15s ease-in;
  transform: translateY(-160px);

  @media (max-width: 767px) {
    transition: all 0.2s ease-in;
  }

  &.employee {
    height: 135px;

    @media (max-width: 767px) {
      height: 135px;
    }
  }

  &.new-employee {
    height: 158px;

    @media (max-width: 440px) {
      height: 148px;
    }
  }

  &.employee-edit-height {
    height: 200px;

    @media (max-width: 767px) {
      height: 175px;
    }
  }

  &.company {
    height: 155px;

    @media (max-width: 767px) {
      height: 150px;
    }
  }

  &.sps--blw {
    opacity: 1;
    transform: translateY(0px);
    left: 30px;

    @media (max-width: 991px) {
      left: 0;
    }
  }
}

.m-top {
  margin-top: 35px;
  margin-bottom: -20px;

  @media (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: -5px;
  }
}

@media (min-width: 768px) {
  .margin-employee {
    margin-left: 0.9rem;
  }

  .margin-company {
    margin-left: 0.6rem;
  }
}

@media (max-width: 767px) {
  .margin-top-box-employee {
    margin-bottom: -40px;
  }
}

.margin-icon-edit {
  margin-bottom: -60px;
}

.margin-top-box {
  @media (max-width: 350px) {
    font-size: 11px;

    .represent {
      width: 143px;
    }
  }
}

.font-size-responsive {
  @media (max-width: 350px) {
    font-size: 16px;
  }
}

.container-profile {
  top: 58px;
  background-color: $white;
  margin-bottom: 20px !important;
  padding-bottom: 0.1px !important;
  height: 132px;

  &.profile-edit {
    margin-bottom: 0px !important;
  }
}

.shadow-bottom {
  box-shadow: 0px 7px 5px -5px rgba(0, 0, 0, 0.08);

  @media (max-width: 767px) {
    box-shadow: none;
  }
}

.title-box {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  position: sticky;
  z-index: 300;

  .avatar {
    transition: all 0.15s ease-in-out;

    @media (max-width: 767px) {
      transition: all 0.2s ease-in;
    }
  }

  .icon-edit-company {
    position: relative;
    bottom: 90px;
    left: 8px;

    @media (max-width: 350px) {
      bottom: 90px;
      left: 15px;
    }
  }

  .button-edit-company {
    margin-top: 3rem;

    @media (max-width: 768px) {
      margin-top: 3.4rem;
    }
  }

  .mt-profile-edit {
    margin-top: 0px;

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .button-edit-employee {
    @media (max-width: 767px) {
      position: relative;
      bottom: 312px;
    }
  }

  &.h-new-employee {
    top: 35px;
  }

  .work,
  .info,
  .hb {
    margin-bottom: 0;
  }

  .alert-p {
    margin-bottom: 0;

    .dot {
      width: 8px;
      height: 10px;

      &.success {
        background: $success;
      }

      &.danger {
        background: $danger;
      }
    }
  }

  &.employee-admin {
    top: 50px;
  }

  .xs-font-size {
    font-size: 18px;
    margin-top: 5px;

    @media (max-width: 363px) {
      margin-top: 8px;
      font-size: 14px;
    }

    @media (max-width: 280px) {
      font-size: 13px;
      margin-top: 10px;
    }
  }

  .first-info {
    padding-left: 1.2rem;

    @media (min-width: 1200px) and (max-width: 1650px) {
      padding-left: 3.3rem;
    }

    @media (max-width: 1199px) {
      padding-left: 0;
    }
  }

  .second-info {
    border-left: 1px solid $gray-400;
    padding-left: 30px;
  }

  @media (max-width: 767px) {
    .first-info {
      padding: 10px 0 20px 5px;
    }

    .second-info {
      padding-left: 20px;
      border-left: none;
      border-top: 1px solid $gray-400;
      border-bottom: 1px solid $gray-400;
      padding-bottom: 30px;
    }
  }

  .show-mobile {
    display: none;
  }

  &.sps--blw {
    height: 86px;
    margin-bottom: 0;

    &.employee {
      height: none;

      @media (max-width: 767px) {
      }
    }

    .hidden-mobile {
      display: none;
    }

    .show-mobile {
      display: inline-block;
      width: 240px;
      margin-left: 65px;
      margin-top: 3px;
    }

    .avatar {
      &.employee {
        height: 75px;
        width: 80px;

        @media (max-width: 767px) {
          height: 55px;
          width: 55px;
        }
      }

      &.company {
        height: 80px;
        width: 160px;
        bottom: 30px;

        @media (max-width: 767px) {
          height: 70px;
          width: 125px;
        }

        @media (max-width: 350px) {
          width: 120px;
        }
      }
    }

    .margin-top-box {
      margin-top: -55px;
    }

    .m-top {
      margin-top: 15px;
    }

    .mt-profile-edit {
      margin-top: 0px;
    }

    &.company-box {
      top: 65px;

      @media (max-width: 991px) {
        top: 58px;
      }
    }

    &.employee-box {
      top: 50px;
    }

    .icon-edit-company {
      bottom: 55px;

      @media (max-width: 350px) {
        bottom: 70px;
      }
    }

    .button-edit-company {
      margin-top: 1rem;

      @media (max-width: 768px) {
        margin-top: 1.4rem;
      }
    }

    .button-edit-employee {
      @media (max-width: 767px) {
        bottom: 45px;
      }
    }

    .button-edit-title-box {
      @media (max-width: 767px) {
        bottom: 150px !important;
      }
    }

    .first-info {
      position: relative;
      right: 2.5rem;
    }

    .position-top-box {
      position: relative;
      left: 20px;
    }

    @media (max-width: 767px) {
      .second-info {
        display: none;
      }

      .position-top-box {
        width: 230px;
        left: 110px;
        bottom: 70px;
      }
    }

    @media (max-width: 350px) {
      .position-top-box {
        width: 170px;
      }

      .employee-edit-top-box {
        bottom: 70px;
      }
    }

    h4 {
      display: none;
    }

    p {
      margin-bottom: 0;
    }

    .represent {
      display: none;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 5px;
  font-size: 16px;
}

.pagination li a {
  display: inline-block;
  padding: 8px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 3px;
  text-decoration: none;
}

.pagination li a:hover {
  background-color: #eaeaea;
}

.pagination li.active a {
  background-color: #29b48f;
  border-color: #29b48f;
  color: #fff;
}

.info-box {
  margin: 0 15px;

  h4 {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .info,
  .info-md {
    span {
      font-family: $font-family-sans-serif;
      font-weight: 500;
      color: $gray-500;
      font-size: $font-size-sm;
      width: 240px;
      display: inline-block;

      &.full-width {
        width: 100%;
      }
    }

    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $dark;
    font-size: $font-size-sm;
    margin-bottom: 7px;

    a {
      font-family: $font-family-sans-serif;
      font-weight: 500;
      color: $dark;
      font-size: $font-size-sm;
    }
  }

  .info-md {
    span {
      width: 150px;
    }
  }

  &.custom-box div {
    padding-left: 0;
    padding-right: 0;
  }

  .info-settlement {
    font-family: $font-family-sans-serif;
    font-size: $font-size-sm;
    font-weight: 500;
    color: $gray-500;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    span {
      color: $gray-700;
      display: inline-block;
      vertical-align: middle;

      &.title-xs {
        width: 115px;
      }

      &.title-sm {
        width: 135px;
      }

      &.title-md {
        width: 180px;
      }

      &.title-lg {
        width: 215px;
      }
    }
  }

  hr {
    margin-bottom: 30px;
    margin-top: 30px;
    border-color: $gray-400;
  }
}

.rdt_Pagination {
  font-size: 15px;
}

.dot {
  min-width: 10px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;

  &-primary {
    background-color: $primary;
  }

  &-success {
    background-color: $success;
  }

  &-danger {
    background-color: $danger;
  }

  &-warning {
    background-color: $warning;
  }

  &-dark {
    background-color: $dark;
    color: $white;
  }

  &-info {
    background-color: $info;
    color: $white;
  }

  &-grey {
    background-color: $gray-400;
  }

  &-workflow {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-big {
    min-width: 15px;
    height: 15px;
    width: 15px;
  }
}

// MODAL
.modal-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 20px;
  color: #515151;
  text-transform: uppercase;
}

.modal-header {
  padding: 2rem 2rem 1rem 2rem;
}

.fade {
  transition: opacity 0.2s ease-in-out;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}

.subtitle-simple-modal {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  color: $gray-500;
  font-size: $font-size-sm;
}

.status {
  padding: 5px 10px;
  border-radius: 10px;
  color: $white;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 12px;

  &-danger {
    background: $danger;
  }

  &-info {
    background: $info;
  }

  &-primary {
    background: $primary;
  }

  &-warning {
    background: $warning;
  }

  &-lg-font {
    font-size: 16px;
  }
}

.team-management {
  border-width: 2px;
  border-color: white;
  border-top-style: solid;
  border-bottom-style: solid;
  padding-top: 5px;
  width: 100%;

  &-title {
    color: white;
    margin: 10px 0 40px 20px;
  }
}

.spinner-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
  z-index: 20;
}

.attendance-card {
  text-align: right;

  &-title {
    text-align: center;
  }

  &-hours {
    padding-left: 0;
  }
}

.min-table-size {
  .table-responsive {
    min-height: 0;
  }
}

.table-responsive {
  overflow-x: visible;
  min-height: 400px;

  @media (max-width: 768px) {
    overflow-x: auto;
    min-height: 500px;
  }

  .table {
    thead {
      border-top: 2px solid $gray-300;
    }

    tbody {
      border-bottom: 2px solid $gray-300;

      tr {
        &:not(:last-of-type) {
          border-bottom: 1px solid $gray-300;
        }

        td {
          border-top: none;

          &.employee-name {
            display: inline-flex;
            align-items: center;
            width: 100%;

            .avatar {
              width: 30px;
              height: 30px;
              margin-left: 0;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.warning-excess-accumulated-periods {
  color: $danger;
  left: 50%;
  margin-left: -125px;
  position: absolute;
  top: 90%;
  width: 250px;
}

div,
textarea {
  &:focus {
    outline: none;
  }
}

// React context menu
.react-contextmenu {
  background-color: $light;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: $dark;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;

  &--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: $dark;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;

  &--active,
  &--selected {
    color: $light;
    background-color: $primary;
    border-color: $primary;
    text-decoration: none;
  }

  &--disabled {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: $gray-400;

    &:hover {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.15);
      color: $gray-400;
    }
  }

  &.react-contextmenu-submenu {
    padding: 0;

    > .react-contextmenu-item {
      &:after {
        display: inline-block;
        position: absolute;
        right: 7px;
      }
    }
  }
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;

  &:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
}

.example-multiple-targets {
  &::after {
    content: attr(data-count);
    display: block;
  }
}

// Others

.advance-search {
  height: 60px !important;
  width: 60px !important;
}

.scroll-x {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
}

.version-index {
  background: $light;
  padding: 1rem 0.3rem;
  align-items: center;
  margin: 0 0 1.5rem 0;

  p {
    margin-bottom: 0;
  }
}

.default-scroll-box {
  height: 20rem;
  overflow-y: auto;

  &.lower-height {
    height: 13rem;
  }

  &.negative-margin {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  &.comment-height {
    max-height: 20rem;
    height: unset;
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    background-color: darken($light, 4%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparentize(white, 0.6);
    border-radius: 10px;
    outline: none;
  }
}

.info-block {
  border-radius: 15px;
  height: 50px;
  align-items: center;

  &-warning {
    background-color: $warning;
  }

  h4 {
    color: $white;
  }
}

.show-info-title {
  letter-spacing: 0.025rem;
  font-family: $font-family-sans-serif;
  font-weight: 300;
  color: $gray-500;
}

.border-responsive {
  @media (max-width: 576px) {
    border-top: 1px solid $gray-400;
    padding-top: 25px;
    margin-top: 10px;
  }

  @media (min-width: 577px) and (max-width: 991px) {
    margin-top: 35px;
  }
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-auto {
  z-index: auto;
}

.margin-fix {
  margin: 25px 0 20px 0;
}

.f-size-employee {
  @media (max-width: 440px) {
    font-size: 1.1rem;
  }

  @media (max-width: 357px) {
    font-size: 0.8rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.workers {
  font-size: 17px;
  color: #959595;
  font-family: futura-pt-bold, sans-serif;
}

.how-section {
  justify-items: center;
  font-size: 1rem;

  .col-12 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .col-md-7 {
    padding-left: 10rem;
    padding-right: 0;
  }

  .col-md-5 {
    padding-right: 10rem;
    padding-left: 0;
  }

  @media (max-width: 1024px) {
    .col-12 {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    .col-md-7 {
      padding-left: 10rem;
      padding-right: 0;
    }

    .col-md-5 {
      padding-right: 10rem;
      padding-left: 0;
    }
  }

  @media (max-width: 768px) {
    .col-12 {
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .col-md-7 {
      padding-left: 1rem;
      padding-right: 0;
    }

    .col-md-5 {
      padding-right: 1rem;
      padding-left: 0;
    }
  }
}

.list-group {
  div:last-child {
    border-bottom-width: 0;
  }

  .list-group-item {
    &:hover {
      background-color: $light;
    }
  }
}

.list-two-columns {
  @media (min-width: 500px) {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    div {
      columns: 1;
    }
  }
}

.question-choice {
  background-color: $gray-200;
  border-radius: 20px;
  max-width: 30rem;
  cursor: pointer;

  &.selected {
    background-color: lighten($primary, 40);
  }

  .form-check-input {
    margin-top: 15px;
    margin-left: -10px;
    width: 1.2em;
    height: 1.2em;
    display: flex;
  }

  label {
    margin-top: 12px;
    padding-bottom: 0px;
    margin-left: 10px;
    background-color: unset;
    color: $dark;
  }
}

.likert {
  flex-direction: row;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  .likert-choice {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: $gray-200;
    border-radius: 20px;
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
    padding-left: 0;

    &.selected {
      background-color: lighten($primary, 40);
    }

    .form-check-input {
      width: 1.2em;
      height: 1.2em;
      margin: 10px 0 10px 0;
      display: flex;
    }

    label {
      margin-top: 30px;
      background-color: unset;
      color: $dark;
      max-width: 150px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.likert-choice-form {
  width: 130px;
  min-width: 130px;
}

a.btn.disabled {
  background: #b1b1b1;
  border-color: #b1b1b1;
  color: $white;
}

.question-plot {
  height: 220px;
}

.table-custom-background.table-dblock {
  .table-responsive {
    display: block;
  }
}

.table-custom-background {
  background-color: $gray-200;
  border-radius: 12px;

  .table-responsive {
    display: flex;
    align-items: center;
    overflow-x: auto;
    min-height: 130px;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    border-right: 1.5rem solid #f8f8f8;
    border-left: 1.5rem solid #f8f8f8;
    border-radius: 12px;

    .table {
      border-top: hidden;
      border-bottom: hidden;
      margin-bottom: 0;

      .sticky-column {
        position: sticky;
        left: 0;
        z-index: 90;
        background-color: $gray-200;
      }

      thead {
        color: #303030;
        font-family: futura-pt-bold, sans-serif;
        font-size: 12px;

        .custom-employee-width {
          min-width: 240px;
        }

        .custom-balance-width {
          min-width: 160px;
        }

        .custom-installments-width {
          min-width: 90px;
        }

        th:not(:nth-last-child(2)).special-division {
          border-right: 1px solid #cecece59;
        }

        .empty-col-1 {
          min-width: calc(166px * 1);

          @media (min-width: 1441px) {
            min-width: calc(166px * 1.7);
          }
        }

        .empty-col-2 {
          min-width: calc(166px * 2);

          @media (min-width: 1441px) {
            min-width: calc(166px * 3.2);
          }
        }

        .empty-col-3 {
          min-width: calc(166px * 3);

          @media (min-width: 1441px) {
            min-width: calc(166px * 4.6);
          }
        }

        .empty-col-4 {
          min-width: calc(166px * 4);

          @media (min-width: 1441px) {
            min-width: calc(166px * 5.6);
          }
        }

        .empty-col-xl-1 {
          @media (min-width: 1441px) {
            min-width: calc(166px * 0.8);
          }
        }

        .empty-col-xl-2 {
          @media (min-width: 1441px) {
            min-width: calc(166px * 1.8);
          }
        }

        .empty-col-xl-3 {
          @media (min-width: 1440px) {
            min-width: calc(166px * 2.8);
          }
        }
      }

      tbody {
        tr:not(:last-of-type) {
          border-bottom: 1px solid #cecece59;
        }

        td:not(:nth-last-child(2)).special-division {
          border-right: 1px solid #cecece59;
        }
      }
    }
  }
}

.word-break {
  overflow-wrap: break-word;
}

.position-sm-absolute {
  @media (min-width: 576px) {
    position: absolute;
  }
}

.custom-badge {
  padding-right: 0.3rem;
  padding-bottom: 0.25rem;
  padding-left: 0.35rem;
}

.max-width-290 {
  max-width: 290px;
}

.max-height-300 {
  max-height: 300px;
}

.custom-step-line {
  flex: 1;
  border-bottom: 1px solid $gray-300;
  height: 15px;

  &.line-none {
    border-bottom: none;
  }

  &.line-xs-none {
    @media (max-width: 575px) {
      border-bottom: none;
    }
  }
}

.btn-login {
  background: linear-gradient(90deg, rgba(153, 235, 0, 1) 0%, rgba(41, 180, 143, 1) 50%);
  border: none;
  border-radius: 9px !important;
  width: 100%;
}

.lnk-login {
  color: #2ab28d;
  font-size: 14px;
  text-align: center;
}

.circular-progress {
  position: 'absolute';
  height: '100%';
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
}

.box-filters-border {
  border: 2px solid rgb(206, 206, 206);
  border-radius: 5px;
  padding: 10px;
}

.d-grid {
  display: grid;
}
